@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins/Poppins-Italic.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins/Poppins-Bold.ttf');
}