/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import 'swiper/swiper-bundle.css';

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "./theme/fonts.scss";


:root {
    --ion-color-new: #4d4d4d;
    --ion-color-new-rgb: 77, 77, 77;
    --ion-color-new-contrast: #ffffff;
    --ion-color-new-contrast-rgb: 255, 255, 255;
    --ion-color-new-shade: #444444;
    --ion-color-new-tint: #5f5f5f;

    --ion-text-color: #4d4d4d;
    --ion-text-color-rgb: 77, 77, 77;

    --ion-color-step-50: #757575;
    --ion-color-step-100: #707070;
    --ion-color-step-150: #6b6b6b;
    --ion-color-step-200: #666666;
    --ion-color-step-250: #616161;
    --ion-color-step-300: #5c5c5c;
    --ion-color-step-350: #575757;
    --ion-color-step-400: #525252;
    --ion-color-step-450: #4d4d4d;
    --ion-color-step-500: #484848;
    --ion-color-step-550: #434343;
    --ion-color-step-600: #3e3e3e;
    --ion-color-step-650: #393939;
    --ion-color-step-700: #343434;
    --ion-color-step-750: #2f2f2f;
    --ion-color-step-800: #2a2a2a;
    --ion-color-step-850: #252525;
    --ion-color-step-900: #202020;
    --ion-color-step-950: #1b1b1b;

    --ion-tab-bar-background: #ffffff;
    --ion-tab-bar-color: #000;
    --ion-tab-bar-color-selected: #ec4899;

    .sc-ion-action-sheet-ios-h {
        --background: #fff1f2;
        --color: #000;
    }
}


:root.dark {
    --ion-background-color: #1a202c; // Cor de fundo para dark mode
    --ion-text-color: #ffffff; // Cor de texto para dark mode
}


.ion-color-rose {
    --ion-color-base: var(--ion-color-rose);
    --ion-color-base-rgb: var(--ion-color-rose-rgb);
    --ion-color-contrast: var(--ion-color-rose-contrast);
    --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
    --ion-color-shade: var(--ion-color-rose-shade);
    --ion-color-tint: var(--ion-color-rose-tint);
}

ion-item {
    --transition: none;
}


.pb-0 {
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

ion-datetime {
    --background: #fff1f2;
    --background-rgb: 255, 241, 242;

    border-radius: 16px;
    box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
}